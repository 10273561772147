<template>
	<div class="input-wrapper" :class="finalWrapperClass">
		<div class="txt-input-label" v-if="label"><label :for="id">{{ label }}</label></div>
		<div class="form-container">
			<div class="form centered">
				<div class="row input-element">
					<img v-if="iconName && !textarea" :src="finalIcon" :alt="iconName" draggable="false" @click="collapsable && toggleCollapsed()">
					<input
						v-if="!textarea"
						ref="input"
						:type="passwordVisible ? 'text' : type"
						:placeholder="placeholder"
						:id="id"
						:name="name ? name : id"
						:value="currentValue"
						:disabled="isCollapsed"
						:readonly="readonly"
						@click="emitClick"
						@focusout="emitFocusout"
						@focusin="emitFocusin"
						@input="handleInput"
						@keyup.enter="handleEnter"
						:autocomplete="autocompleteName"
					>
					<textarea
						v-if="textarea"
						ref="input"
						:placeholder="placeholder"
						:id="id"
						:name="name"
						:readonly="readonly"
						@click="$emit('click-input')"
						@focusout="$emit('focusout-input')"
						@input="handleInput">{{ currentValue }}</textarea>
				</div>
			</div>
			<button v-if="type === 'password'" class="button-visibility" tabindex='-1' @click='togglePasswordVisibility' :aria-label='passwordVisible ? "Hide password" : "Show password"'>
				<img v-if="passwordVisible === false" src="https://img.qromo.io/img/Icon-eye-slash.svg">
				<img v-if="passwordVisible === true" src="https://img.qromo.io/img/Icon-eye.svg">
			</button>
		</div>
	</div>
</template>

<script>

import {getRandomAlphanumericString} from "@/shared/helpers/strings";

export default {
	name: "AppTextInput",
	emits: ["changing-text", "changed-text", "pressed-enter", "toggling-collapse", "toggled-collapse", "click-input", "focusin-input", "focusout-input"],
	data() {
		return {
			isCollapsed: false,
			animating: false,
			currentValue: this.value,
			changingTextTimeout: null,
			passwordVisible: false,
		}
	},
	props: {
		value: {type: String, default: ""},
		placeholder: {type: String, default: ""},
		label: {type: String, default: null},
		type: {type: String, default: "text"},
		name: {type: String, default: ""},
		iconName: {type: String, default: null},
		collapsable: {type: Boolean, default: false},
		textarea: {type: Boolean, default: false},
		autoGrow: {type: Boolean, default: false},
		noAutocomplete: {type: Boolean, default: false},
		readonly: {type: Boolean, default: false},
	},
	methods: {
		emitClick(e) {
			this.$emit("click-input", e);
		},
		emitFocusout(e) {
			this.$emit("focusout-input", e, this.currentValue);
		},
		emitFocusin(e){
			this.$emit("focusin-input", e, this.currentValue);
		},
		toggleCollapsed() {
			if (this.collapsable && !this.animating) {
				this.animating = true;
				this.isCollapsed = !this.isCollapsed;
				this.$emit("toggling-collapse", this.isCollapsed);
				setTimeout(() => {
					this.animating = false;
					if (!this.isCollapsed) {
						this.$refs.input.focus();
					}
					this.$emit("toggled-collapse", this.isCollapsed);
				}, 300)
			}
		},
		focus() {
			if(this.$refs && this.$refs.input) {
				this.$refs.input.focus();
			}
		},
		autoExpandTextarea() {
			let minHeightLimit = 60;
			let maxHeightLimit = 250;
			let textarea = this.$refs.input;
			textarea.style.height = "";
			let finalValue = textarea.scrollHeight > minHeightLimit ? textarea.scrollHeight : minHeightLimit;
			finalValue = finalValue > maxHeightLimit ? maxHeightLimit : finalValue;
			textarea.style.height = finalValue + "px";
		},
		handleInput(e) {
			this.currentValue = e.target.value;
			if (this.textarea && this.autoGrow) {
				this.autoExpandTextarea();
			}
			this.$emit("changing-text", e.target.value);

			if(this.changingTextTimeout) {
				clearTimeout(this.changingTextTimeout);
			}
			this.changingTextTimeout = setTimeout(() => {
				this.$emit("changed-text", e.target.value);
				this.changingTextTimeout = null;
			}, 500);
		},
		handleEnter(e) {
			e.preventDefault();
			this.$emit("pressed-enter", this.currentValue);
		},
		togglePasswordVisibility () {
			this.passwordVisible = !this.passwordVisible
		}
	},
	computed: {
		finalWrapperClass() {
			let finalClass = this.collapsable ? 'collapsable ' : '';
			finalClass += this.isCollapsed ? 'collapsed ' : '';
			return this.textarea ? 'textarea' : finalClass;
		},
		id() {
			return "input-text-"+getRandomAlphanumericString();
		},
		finalIcon() {
			return new URL('https://img.qromo.io/img/icons/' + this.iconName + '.svg', import.meta.url).href;
		},
		autocompleteName() {
			if(!this.noAutocomplete) {
				return '';
			} else if(this.noAutocomplete && this.type === 'password') {
				return 'new-password';
			} else {
				return 'off';
			}
		}
	},
	mounted() {
		if (this.collapsable) {
			if(!this.iconName) {
				console.error('Collapsable input requires an iconName prop');
				return;
			}

			this.toggleCollapsed();
		}
	},
	watch: {
		value(newValue) {
			this.currentValue = newValue;
		}
	}
}
</script>

<style lang="scss" scoped>

.input-wrapper {
	width: 280px;
	min-width: 120px;
	transition: width 0.3s $transition;

	&.collapsed {
		width: 46px;
		.input-element {
			position: relative;
			cursor: pointer;
			width: fit-content;

			input {
				width: 0;
				padding-left: 28px;
				pointer-events: none;

				&[disabled] {
					background-color: #fff;
				}
			}

			img {
				padding: 13px;
				left: 0;
				box-sizing: content-box;
				pointer-events: all;
			}
		}
	}

	&.collapsable img {
		cursor: pointer;
		pointer-events: all;
	}

	.txt-input-label {
		@include noSelect;
		margin-bottom: 5px;
	}

	.form-container {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.form {
			width: 100%;
		}
	}

	.input-element {
		position: relative;

		input, textarea {
			width: 100%;
			border-radius: 13px;
			border: none;
			color: #4e4e4e;
			font-size: 13px;
			@include textSelect();

			&::placeholder {
				color: #4e4e4e;
			}
		}

		input {
			box-shadow: 0 1px 2px $alpha_4;
			height: 40px;
			padding-right: 14px;
			padding-left: 14px;
			transition: width .3s $transition, padding-left .3s $transition;
			@include textSelect();
		}

		textarea {
			@include smallScrollbar;
			padding: 6px 12px;
			max-width: 100%;
			resize: vertical;
			height: 60px;
			min-height: 60px;
			max-height: 250px;
		}

		img {
			box-sizing: content-box;
			position: absolute;
			width: 16px;
			height: 16px;
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
			transition: left .3s $transition, padding .3s $transition;
		}

		img ~ input {
			padding-left: 34px;
		}
	}

	.button-visibility {
		width: 22px;
		height: 22px;
		background-color: transparent;
		border: none;
		margin-left: 8px;
		img	{
			object-fit: contain;
			width: 100%;
			height: 100%;
		}
	}

	&.textarea .input-element {
		padding: 4px;
		border-radius: 13px;
		box-shadow: 0 1px 2px $alpha_4;
		background-color: #fff;
	}

	@media (max-width: 450px) {
		width: 220px;
	}

}
</style>